<template>
  <div class="card p-shadow-6">
    <h1>Folhas de Instalação Por Concluir</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Divider />

    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog />
    <DataTable
      :value="dossierList"
      :filters.sync="filters"
      filterDisplay="row"
      :loading="loading"
      :paginator="true"
      :rows="20"
      class="p-datatable-sm"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50]"
      currentPageReportTemplate="Mostrar {first} ao {last} de {totalRecords} instalações"
      sortField="name"
      :sortOrder="1"
      :autoLayout="true"
      responsiveLayout="scroll"
    >
      <template #empty> Nada a mostrar. </template>
      <template #loading> A carregar. Por favor Aguarde... </template>
      <Column
        field="sheetNumber"
        header="Folha de Obra"
        sortable
        :headerStyle="{ width: '70px' }"
        filterField="sheetNumber"
      >
        <template #body="slotProps">
          {{ slotProps.data.sheetNumber }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="installationId"
        header="Intalação"
        sortable
        :headerStyle="{ width: '70px' }"
        filterField="installationId"
      >
        <template #body="slotProps">
          {{ slotProps.data.installationId }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="installationName"
        header="Nome"
        sortable
        filterField="installationName"
      >
        <template #body="slotProps">
          {{ slotProps.data.installationName }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="installationDescription"
        header="Descrição"
        sortable
        filterField="installationDescription"
      >
        <template #body="slotProps">
          {{ slotProps.data.installationDescription }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="installationSeller"
        header="Vendedor"
        sortable
        filterField="installationSeller"
      >
        <template #body="slotProps">
          {{ slotProps.data.installationSeller }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="installationResponsibleTechnical"
        header="Responsável"
        sortable
        filterField="installationResponsibleTechnical"
      >
        <template #body="slotProps">
          {{ slotProps.data.installationResponsibleTechnical }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="installationTechnician"
        header="Técnico"
        sortable
        filterField="installationTechnician"
      >
        <template #body="slotProps">
          {{ slotProps.data.installationTechnician }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column :headerStyle="{ width: '95px' }">
        <template #body="slotProps">
          <Button
            v-tooltip.left="'Ir para folha de instalação'"
            icon="pi pi-book"
            class="p-button-rounded p-button-info p-button-outlined p-ml-1"
            @click="
              $router.push(
                `/task-installation-sheet/${slotProps.data.installationId}/1`
              )
            "
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import dossierService from "../services/dossierLines.service";
import { FilterMatchMode } from "primevue/api/";

export default {
  name: "InstallationsOpenDossiers",
  data() {
    return {
      loading: true,
      dossierList: [],
      filters: {
        sheetNumber: { value: null, matchMode: FilterMatchMode.EQUALS },
        installationId: { value: null, matchMode: FilterMatchMode.EQUALS },
        installationName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        installationDescription: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        installationTechnician: {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        installationResponsibleTechnical: {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        installationSeller: {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },

      },
    };
  },
  created() {
    this.loading = true;
    this.openDossiers();
  },
  methods: {
    openDossiers() {
      this.loading = true;
      return dossierService
        .listUnfinished(this.$store.state.auth.user.id)
        .then((response) => {
          this.loading = false;
          return (this.dossierList = response);
        });
    },
  },
};
</script>
<style scoped lang="scss">
#year-selector {
  width: 60px;
}

.p-column-filter {
  width: 100%;
}
</style>
